<template>
  <div
    v-if="errorMessage"
    role="alert"
    class="flex gap-2 w-full justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-h-20 overflow-auto"
  >
    <!-- Icon Container -->
    <div
      class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
    >
      <img
        src="../../assets/shared/failure.svg"
        alt="Error Icon"
        class="w-4 h-4"
      />
    </div>
    <p>{{ errorMessage }}</p>
  </div>
  <div class="flex flex-col w-full max-md:mt-8 max-md:max-w-full">
    <div class="flex flex-col gap-4 max-md:max-w-full">
      <div class="flex gap-5">
        <div class="flex flex-wrap gap-5 flex-1">
          <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
            >
              <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
                <div
                  class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
                >
                  <div v-html="CALENDAR_ICON" class="text-white"></div>
                </div>
                <div
                  class="flex flex-col flex-1 shrink self-stretch my-auto whitespace-nowrap basis-6"
                >
                  <div
                    class="text-sm font-medium leading-none text-gray-500 text-left"
                  >
                    Upcoming Meetings
                  </div>
                  <div
                    class="gap-2 self-start text-2xl mt-2 font-semibold leading-none text-zinc-800"
                  >
                    {{ upcomingMeetings }}
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
            >
              <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
                <div
                  class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
                >
                  <div v-html="PEOPLE_ICON" class="text-white"></div>
                </div>
                <div
                  class="flex flex-col flex-1 shrink self-stretch my-auto basis-6"
                >
                  <div
                    class="text-sm font-medium leading-none text-gray-500 text-left"
                  >
                    Total {{ program.name }} Experts are
                  </div>
                  <div
                    class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
                  >
                    {{ totalExperts }}
                  </div>
                </div>
              </div>
            </div>
          </article>
          <article class="flex flex-col flex-1 max-md:ml-0 max-md:w-full">
            <div
              class="flex flex-col mx-auto w-full bg-white rounded-lg border border-gray-300 border-solid max-md:mt-5 h-full"
            >
              <div class="flex gap-5 items-center p-6 w-full max-md:px-5">
                <div
                  class="flex justify-center items-center self-stretch px-3 my-auto w-12 h-12 bg-violet-600 rounded-md"
                >
                  <div v-html="EARNINGS_ICON" class="text-white"></div>
                </div>
                <div
                  class="flex flex-col flex-1 shrink self-stretch my-auto basis-6"
                >
                  <div
                    class="text-sm font-medium leading-none text-gray-500 text-left"
                  >
                    Total Thank You Earnings
                  </div>
                  <div
                    class="gap-2 self-start text-2xl mt-2 font-semibold leading-none whitespace-nowrap text-zinc-800"
                  >
                    ${{ earnings?.toFixed(2) || 0 }} (USD)
                  </div>
                </div>
              </div>
            </div>
          </article>
        </div>
      </div>
      <div
        v-if="profile"
        class="flex flex-col text-left gap-4 max-md:ml-0 max-md:w-full"
      >
        <div class="flex flex-wrap w-full justify-between items-center">
          <div class="flex flex-col">
            <span class="text-gray-700 text-lg font-medium leading-5"
              >Profile</span
            >
          </div>
          <div class="flex flex-wrap gap-2 text-left">
            <div class="flex flex-col" v-if="editProfile">
              <BaseButton
                type="button"
                @click="handleCancel"
                class="flex flex-col justify-center items-center p-3 w-full text-black border border-gray-300 rounded-md text-sm font-medium leading-none text-center"
              >
                Cancel
              </BaseButton>
            </div>
            <div class="flex flex-col">
              <BaseButton
                type="button"
                @click="handleEdit"
                class="flex flex-col justify-center max-w-max items-center p-3 w-full text-white bg-primary-purple rounded-md text-sm font-medium leading-none text-center"
              >
                {{ editButtonText }}
              </BaseButton>
            </div>
          </div>
        </div>
        <div class="flex flex-col gap-4 text-left">
          <div class="flex flex-col gap-2">
            <div class="text-gray-900 font-medium text-base leading-6">
              Expert Quiz
            </div>
            <div class="text-gray-800 text-sm font-normal leading-1">
              Question 1: Rate your expertise from 1 to 5?
            </div>
            <div
              v-if="editProfile"
              class="flex flex-col items-start space-y-4 py-6 px-4"
            >
              <div class="relative w-1/2 md:w-1/3">
                <div
                  class="absolute -translate-x-1/2 -translate-y-6 bg-primary-purple text-white text-xs font-medium px-2 py-1 rounded shadow-md"
                  :style="{
                    left: `${((newExpertise - 1) / (5 - 1)) * 100}%`,
                  }"
                >
                  {{ expertiseValue(newExpertise) }}
                </div>

                <input
                  id="slider"
                  type="range"
                  v-model.number="newExpertise"
                  min="1"
                  max="5"
                  step="1"
                  class="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2"
                />
                <div class="flex flex-col">
                  {{ expertiseValue(newExpertise) }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="text-gray-800 text-sm font-normal leading-1">
                {{ newExpertise }}
                ({{ expertiseValue(newExpertise) }})
              </div>
            </div>
            <div class="text-gray-800 text-sm font-normal leading-1">
              Question 2: How long have you been using this software?
            </div>
            <div
              v-if="editProfile"
              class="flex flex-col items-start space-y-4 py-6 px-4"
            >
              <div class="relative w-1/2 md:w-1/3">
                <div
                  class="absolute -translate-x-1/2 -translate-y-6 bg-primary-purple text-white text-xs font-medium px-2 py-1 rounded shadow-md whitespace-nowrap"
                  :style="{
                    left: `${((newExperience - 1) / (5 - 1)) * 100}%`,
                  }"
                >
                  {{ experienceTimeDuration(newExperience) }}
                </div>

                <input
                  id="slider"
                  type="range"
                  v-model.number="newExperience"
                  min="1"
                  max="5"
                  step="1"
                  class="w-full h-2 bg-gray-300 rounded-lg appearance-none cursor-pointer accent-primary-purple focus:outline-none focus:ring-2 focus:ring-primary-purple focus:ring-offset-2"
                />
                <div class="flex flex-col">
                  {{ experienceUserType(newExperience) }}
                </div>
              </div>
            </div>
            <div v-else>
              <div class="text-gray-800 text-sm font-normal leading-1">
                {{ experienceTimeDuration(newExperience) }} ({{
                  experienceUserType(newExperience)
                }})
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, PropType, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import { Program } from "@/types/dashboard/types";
import { gql } from "@apollo/client/core";
import { useMutation, useQuery } from "@vue/apollo-composable";
import {
  CALENDAR_ICON,
  PEOPLE_ICON,
  EARNINGS_ICON,
} from "@/assets/svg/expert-overview/svgConstants";
import { JoinProgramRequest, Profile } from "@/types/expertise/types";

const GET_BOOKINGS = gql`
  query GetBookings($totalBookings: Boolean, $programId: String) {
    listBooking(totalBookings: $totalBookings, programId: $programId) {
      totalCount
    }
  }
`;

const GET_EXPERTS = gql`
  query GetExperts($programId: ID!, $all: Boolean) {
    fetchJoinProgramRequest(programId: $programId, all: $all) {
      request {
        status
      }
      errors
    }
  }
`;

const GET_EARNINGS = gql`
  query GetEarnings($programId: ID) {
    getExpertEarnings(programId: $programId) {
      programEarnings
    }
  }
`;

const UPDATE_USER_PROGRAM_PROFILE = gql`
  mutation updateUserProgramProfile($input: UpdateUserProgramProfileInput!) {
    updateUserProgramProfile(input: $input) {
      profile {
        id
        programId
        companyId
        introductionVideo
        expertise
        experience
      }
      errors
    }
  }
`;

export default defineComponent({
  name: "AcceptedProgramDetailsSection",
  components: {
    BaseButton,
  },
  props: {
    program: {
      type: Object as PropType<Program>,
      required: true,
    },
    profile: {
      type: Object as PropType<Profile | null>,
      required: false,
    },
    handleChildComponentError: {
      type: Function as PropType<(message: string) => void>,
      required: true,
    },
  },
  setup(props) {
    const errorMessage = ref("");
    const upcomingMeetings = ref(0);
    const totalExperts = ref(0);
    const earnings = ref(0);
    const editProfile = ref(false);
    const isDisabled = ref(false);
    const editButtonText = ref("Edit");
    const oldExpertise = ref(props.profile?.expertise || 1);
    const newExpertise = ref(props.profile?.expertise || 1);
    const oldExperience = ref(props.profile?.experience || 1);
    const newExperience = ref(props.profile?.experience || 1);

    const {
      mutate: updateUserProgramProfile,
      onDone: onUpdateProfileDone,
      onError: onUpdateProfileError,
    } = useMutation(UPDATE_USER_PROGRAM_PROFILE);

    const getBookings = () => {
      const { onResult, onError } = useQuery(
        GET_BOOKINGS,
        {
          totalBookings: true,
          programId: props.program.id,
        },
        {
          errorPolicy: "all",
          fetchPolicy: "network-only",
        }
      );

      onResult((result) => {
        if (result && result?.data?.listBooking?.totalCount) {
          upcomingMeetings.value = result.data.listBooking.totalCount;
        }
      });

      onError((error) => {
        if (error) {
          props.handleChildComponentError(
            "Failed to fetch necessary data, please refresh page to try again."
          );
        }
      });
    };

    const getExperts = () => {
      const { onResult, onError } = useQuery(
        GET_EXPERTS,
        {
          programId: props.program.id,
          all: true,
        },
        {
          fetchPolicy: "network-only",
        }
      );

      onResult((result) => {
        if (result && result?.data?.fetchJoinProgramRequest?.request) {
          const experts = result.data.fetchJoinProgramRequest.request;
          const activeExperts = experts.filter(
            (expert: JoinProgramRequest) => expert.status === "approved"
          );
          totalExperts.value = activeExperts.length;
        }
      });

      onError((error) => {
        if (error) {
          props.handleChildComponentError(
            "Failed to fetch necessary data, please refresh page to try again."
          );
        }
      });
    };

    const getEarnings = () => {
      const { onResult, onError } = useQuery(
        GET_EARNINGS,
        {
          programId: props.program.id,
        },
        {
          fetchPolicy: "network-only",
        }
      );

      onResult((result) => {
        if (result && result?.data?.getExpertEarnings) {
          earnings.value = result.data.getExpertEarnings.programEarnings;
        }
      });

      onError((error) => {
        if (error) {
          props.handleChildComponentError(
            "Failed to fetch necessary data, please refresh page to try again."
          );
        }
      });
    };

    const truncateText = (text: string, maxLength = 15) => {
      if (text.length > maxLength) {
        return text.slice(0, maxLength) + "...";
      } else if (text.length == 0) {
        return "No description provided.";
      }
      return text;
    };

    const handleEdit = () => {
      if (editProfile.value) {
        handleUpdateProfile();
      } else {
        editProfile.value = true;
        editButtonText.value = "Save";
      }
    };

    const handleCancel = () => {
      editProfile.value = false;
      editButtonText.value = "Edit";
      newExpertise.value = oldExpertise.value;
      newExperience.value = oldExperience.value;
    };

    const handleUpdateProfile = () => {
      isDisabled.value = true;
      editButtonText.value = "Updating...";
      updateUserProgramProfile({
        input: {
          params: {
            profileId: props.profile?.id,
            expertise: newExpertise.value,
            experience: newExperience.value,
          },
        },
      });

      onUpdateProfileDone((response) => {
        if (response) {
          if (response?.errors) {
            props.handleChildComponentError("Something went wrong.");
            isDisabled.value = false;
            editButtonText.value = "Edit";
          } else {
            const errors = response.data.updateUserProgramProfile.errors;
            if (errors.length > 0) {
              props.handleChildComponentError(errors.join(". "));
              isDisabled.value = false;
              editButtonText.value = "Edit";
            } else {
              handleSuccess();
            }
          }
        }
      });

      onUpdateProfileError((error) => {
        if (error) {
          props.handleChildComponentError("Something went wrong..");
          isDisabled.value = false;
          editButtonText.value = "Update";
        }
      });
    };

    const handleSuccess = () => {
      editProfile.value = false;
      editButtonText.value = "Edit";
      isDisabled.value = false;
      oldExpertise.value = newExpertise.value;
      oldExperience.value = newExperience.value;
    };

    const expertiseValue = (value: number) => {
      if (value === 1) {
        return "Novice";
      } else if (value === 2) {
        return "Competent";
      } else if (value === 3) {
        return "Proficient";
      } else if (value === 4) {
        return "Master";
      } else if (value === 5) {
        return "Expert";
      }
    };

    const experienceTimeDuration = (value: number) => {
      if (value === 1) {
        return "<1 month";
      } else if (value === 2) {
        return "1-6 months";
      } else if (value === 3) {
        return "6-12 months";
      } else if (value === 4) {
        return "1-3 years";
      } else if (value === 5) {
        return "3+ years";
      }
    };

    const experienceUserType = (value: number) => {
      if (value === 1) {
        return "Beginner User";
      } else if (value === 2) {
        return "Active User";
      } else if (value === 3) {
        return "Advanced User";
      } else if (value === 4) {
        return "Super User";
      } else if (value === 5) {
        return "Expert User";
      }
    };

    const scrollToTop = () => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    };

    const handleError = (error: string) => {
      errorMessage.value = error;
      scrollToTop();
      setTimeout(() => {
        errorMessage.value = "";
      }, 3000);
    };

    onMounted(() => {
      getBookings();
      getExperts();
      getEarnings();
    });

    return {
      errorMessage,
      totalExperts,
      upcomingMeetings,
      earnings,
      truncateText,
      handleEdit,
      editProfile,
      oldExpertise,
      newExpertise,
      oldExperience,
      newExperience,
      isDisabled,
      editButtonText,
      expertiseValue,
      experienceTimeDuration,
      experienceUserType,
      CALENDAR_ICON,
      PEOPLE_ICON,
      EARNINGS_ICON,
      handleCancel,
    };
  },
});
</script>
