<template>
  <div class="flex flex-col gap-2">
    <div class="flex flex-col text-left">
      <span
        class="text-black font-sans text-4xl font-medium leading-none tracking-tight mb-0 sm:mb-[15px]"
        >A Platform for Experts</span
      >
    </div>
    <div class="flex flex-col">
      <div class="flex flex-row gap-4 text-left">
        <!-- left section -->
        <div class="flex flex-col flex-1 gap-2">
          <div class="flex flex-col relative">
            <!-- background SVG -->
            <div class="flex flex-col z-0">
              <img
                src="@/assets/expert-program/overview/section-two/expert-one.svg"
                alt=""
                class="w-full p-4 rounded-lg object-cover"
              />
            </div>
            <!-- foreground component -->
            <div
              class="absolute bottom-0 right-0 w-2/3 transform -translate-y-1/2 items-center justify-center z-10"
            >
              <ExpertProfile
                :primaryColor="primaryColor"
                class="w-2/3 h-auto"
              />
            </div>
          </div>

          <span
            class="text-black font-sans text-xl sm:text-2xl mb-0 sm:mb-[15px] font-semibold leading-none"
            >Guide, Connect, Make an Impact</span
          >
          <span
            class="text-gray-500 font-sans text-sm sm:text-base font-normal leading-none sm:leading-[1.4em] tracking-tight"
            >Here is your chance to make a difference. We connect people who
            need advice with experts like you, who have the knowledge to guide
            them.</span
          >
        </div>
        <!-- right section -->
        <div class="flex flex-col flex-1 gap-2">
          <div class="flex flex-col justify-center relative mt-24">
            <!-- Foreground SVG -->
            <div
              v-html="AVAILABLE_BALANCE_CARD"
              class="absolute top-0 left-0 w-full h-auto z-10 mt-4 ml-2"
              :style="{ color: primaryColor }"
            ></div>

            <!-- Background Image -->
            <div class="flex flex-col relative z-0 items-center justify-center">
              <img
                src="@/assets/expert-program/overview/section-two/expert-two.svg"
                alt=""
                class="w-full p-4 rounded-lg object-cover"
              />
            </div>
          </div>

          <span
            class="text-black font-sans text-xl sm:text-2xl mb-0 sm:mb-[15px] font-semibold leading-none"
            >Flexibility Meets Opportunity</span
          >
          <span
            class="text-gray-500 font-sans text-sm sm:text-base font-normal leading-none sm:leading-[1.4em] tracking-tight"
            >You get to work on your own schedule, earn money for your insights,
            and build your reputation as a go-to expert in your field.</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { AVAILABLE_BALANCE_CARD } from "@/assets/svg/expert-program-overview/svgConstants";
import ExpertProfile from "@/components/expert-program/overview/assets/SectionTwo/ExpertProfile.vue";

export default defineComponent({
  name: "SectionTwo",
  components: {
    ExpertProfile,
  },
  props: {
    primaryColor: {
      type: String,
      required: true,
    },
  },
  setup() {
    return {
      AVAILABLE_BALANCE_CARD,
    };
  },
});
</script>
