<template>
  <div class="flex flex-col">
    <div class="flex flex-row justify-between">
      <!-- left section -->
      <div class="flex flex-col flex-1 gap-4 text-left pt-4">
        <div class="flex flex-col">
          <span
            class="text-black font-sans text-4xl font-medium leading-none tracking-tight"
            >The Perks of Joining</span
          >
        </div>
        <div class="flex flex-col gap-2">
          <div class="flex flex-row gap-2">
            <div v-html="CHECK_CIRCLE" class="text-yellow-400"></div>
            <span
              class="text-gray-800 font-sans text-base font-normal leading-none tracking-tighter sm:tracking-tight"
              >Work when and where you want.</span
            >
          </div>
          <div class="flex flex-row gap-2">
            <div v-html="CHECK_CIRCLE" class="text-yellow-400"></div>
            <span
              class="text-gray-800 font-sans text-base font-normal leading-none tracking-tighter sm:tracking-tight"
              >Turn your knowledge into a steady revenue stream.</span
            >
          </div>
          <div class="flex flex-row gap-2">
            <div v-html="CHECK_CIRCLE" class="text-yellow-400"></div>
            <span
              class="text-gray-800 font-sans text-base font-normal leading-none tracking-tighter sm:tracking-tight"
              >Help people make smarter decisions.</span
            >
          </div>
          <div class="flex flex-row gap-2">
            <div v-html="CHECK_CIRCLE" class="text-yellow-400"></div>
            <span
              class="text-gray-800 font-sans text-base font-normal leading-none tracking-tighter sm:tracking-tight"
              >Boost your reputation and build new connections.</span
            >
          </div>
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="joinExpertProgram()"
            class="px-4 sm:px-[45px] py-2 sm:py-[10px] max-w-max mt-0 sm:mt-[15px] text-sm sm:text-base font-medium leading-none text-white border border-gray-300 rounded-full sm:rounded-[8px]"
            :style="{ backgroundColor: primaryColor }"
          >
            Join Now
          </BaseButton>
        </div>
      </div>

      <!-- right section -->
      <div class="flex flex-col flex-1">
        <div class="flex flex-col relative">
          <!-- Background SVG -->
          <div
            v-html="LEFT_TITLTED_RECTANGLE"
            class="absolute items-center left-0 w-[100%] h-[100%] z-0"
            :style="{ color: primaryColor }"
          ></div>
          <div class="flex flex-col relative z-10 items-center justify-center">
            <img
              src="@/assets/expert-program/overview/section-four/profile.svg"
              alt=""
              class="w-full p-2 md:p-4 rounded-lg object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, PropType } from "vue";
import { CHECK_CIRCLE } from "@/assets/svg/shared/svgConstants";
import { LEFT_TITLTED_RECTANGLE } from "@/assets/svg/expert-program-overview/svgConstants";
import BaseButton from "@/components/shared/BaseButton.vue";

export default defineComponent({
  name: "SectionFour",
  components: {
    BaseButton,
  },
  props: {
    primaryColor: {
      type: String as PropType<string>,
      required: true,
    },
    joinExpertProgram: {
      type: Function,
      required: true,
    },
  },
  setup() {
    return {
      CHECK_CIRCLE,
      LEFT_TITLTED_RECTANGLE,
    };
  },
});
</script>
