<template>
  <div class="flex justify-center mb-4">
    <div
      v-if="errorMessage"
      role="alert"
      class="flex gap-2 justify-center p-2.5 mt-6 text-sm leading-5 text-center text-red-900 bg-red-50 rounded-lg max-md:flex-wrap"
      style="max-height: 80px; overflow-y: auto"
    >
      <div
        class="flex-shrink-0 w-6 h-6 flex items-center justify-center rounded-full"
      >
        <img
          src="../../assets/shared/failure.svg"
          alt="Error Icon"
          class="w-4 h-4"
        />
      </div>
      <p>{{ errorMessage }}</p>
    </div>
  </div>
  <section class="mt-6 w-full max-md:max-w-full">
    <div class="mb-2 text-left">
      <BaseLabel
        forAttr="payoutMethods"
        text="Thank You Payout Methods:"
        class="text-gray-800 font-sans text-lg font-medium leading-7 text-left"
      />
    </div>
    <div class="flex flex-wrap gap-5 max-md:flex-col">
      <TransferToAccount :handleError="handleError" />
      <CharityDonation />
      <ShopGiftCard />
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import TransferToAccount from "./payoutOptions/TransferToAccount.vue";
import CharityDonation from "./payoutOptions/CharityDonation.vue";
import ShopGiftCard from "./payoutOptions/ShopGiftCard.vue";
import BaseLabel from "../shared/BaseLabel.vue";

export default defineComponent({
  name: "PayoutSection",
  components: {
    TransferToAccount,
    CharityDonation,
    ShopGiftCard,
    BaseLabel,
  },
  setup() {
    const errorMessage = ref("");

    const handleError = (message: string) => {
      errorMessage.value = message;
      setTimeout(() => {
        errorMessage.value = "";
      }, 4500);
    };

    return {
      errorMessage,
      handleError,
    };
  },
});
</script>
