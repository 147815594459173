<template>
  <div class="flex flex-col gap-4 text-left">
    <div class="flex flex-col">
      <span
        class="text-black font-sans text-4xl font-medium leading-none tracking-tight"
        >FAQs</span
      >
    </div>
    <!-- faqs section -->
    <div class="flex flex-col gap-4">
      <!-- question one -->
      <div class="flex flex-col h-[1px] bg-black"></div>
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >What is SlashExperts?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="firstAnswerVisible = !firstAnswerVisible"
          ><span v-if="!firstAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="firstAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >SlashExperts is the first Buyer-Led Growth Platform that
          revolutionizes the B2B buying process by connecting prospects directly
          with successful existing customers. Our booking system enables
          authentic conversations that help buyers make faster, more confident
          purchasing decisions.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question two -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >What does being an expert mean?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="secondAnswerVisible = !secondAnswerVisible"
          ><span v-if="!secondAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="secondAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Experts are successful customers of participating companies who have
          agreed to share their experience implementing and using the company's
          product/platform with prospective buyers. Experts are paid a pre
          determined rate for every meeting that they have.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question three -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >How do I become an expert?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="thirdAnswerVisible = !thirdAnswerVisible"
          ><span v-if="!thirdAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="thirdAnswerVisible">
        <div
          class="max-w-3xl text-left text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
        >
          <p class="mb-3">You can join an Expert Program in two ways.</p>

          <div class="mb-3">
            <h2 class="text-xl font-bold mb-3">
              Through a Company Invitation:
            </h2>
            <ul class="list-disc list-inside ml-2 space-y-1">
              <li>
                Receive an invitation email from a company you have an existing
                relationship with
              </li>
              <li>Click the invitation link to create your account</li>
              <li>Complete your profile setup</li>
              <li>Start accepting conversation requests</li>
            </ul>
          </div>

          <div class="mb-3">
            <h2 class="text-xl font-bold mb-3">Through Self-Application:</h2>
            <ul class="list-disc list-inside ml-4 space-y-1">
              <li>Create your SlashExperts account</li>
              <li>
                Browse available Expert Programs from companies you're a
                customer of
              </li>
              <li>Set up your profile and availability</li>
            </ul>
          </div>

          <p>
            In both cases, once approved, you'll be notified and can begin
            participating in customer conversations.
          </p>
        </div>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question four -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >How much can I earn from being an expert?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="fourthAnswerVisible = !fourthAnswerVisible"
          ><span v-if="!fourthAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="fourthAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Earnings vary by program but typically range from $50-$200 per
          conversation, with potential for higher rates based on expertise and
          demand.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question five -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >How long is an average conversation?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="fifthAnswerVisible = !fifthAnswerVisible"
          ><span v-if="!fifthAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="fifthAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Typical conversations last 10-20 minutes, though it varies by program
          and buyer.
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question six -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >Will I be able to control my availability?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="sixthAnswerVisible = !sixthAnswerVisible"
          ><span v-if="!sixthAnswerVisible">+</span><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="sixthAnswerVisible">
        <div
          class="max-w-3xl text-left text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
        >
          <p class="mb-3">Calendar management features include:</p>
          <ul class="list-disc list-inside ml-2 space-y-1">
            <li>Google/Outlook integration</li>
            <li>Custom availability settings</li>
            <li>Time zone management</li>
            <li>Temporary pause functionality</li>
          </ul>
        </div>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
      <!-- question seven -->
      <div class="flex flex-row justify-between gap-4">
        <span class="text-gray-900 font-sans text-xl font-medium leading-none"
          >Where can I find your Help Center with even more detailed FAQs and
          helpful resources?</span
        >
        <span
          class="text-gray-900 font-sans text-xl font-medium leading-none cursor-pointer"
          @click="seventhAnswerVisible = !seventhAnswerVisible"
          ><span v-if="!seventhAnswerVisible">+</span
          ><span v-else>-</span></span
        >
      </div>
      <div class="flex flex-col" v-if="seventhAnswerVisible">
        <span
          class="text-gray-600 font-sans text-lg font-normal leading-7 tracking-tight"
          >Visit our detailed help center at:
          <a
            href="https://help.slashexperts.com/"
            target="_blank"
            rel="noopener noreferrer"
            class="text-blue-500"
          >
            https://help.slashexperts.com/
          </a>
        </span>
      </div>
      <div class="flex flex-col h-[1px] bg-black"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";

export default defineComponent({
  name: "SectionSix",
  setup() {
    const firstAnswerVisible = ref(false);
    const secondAnswerVisible = ref(false);
    const thirdAnswerVisible = ref(false);
    const fourthAnswerVisible = ref(false);
    const fifthAnswerVisible = ref(false);
    const sixthAnswerVisible = ref(false);
    const seventhAnswerVisible = ref(false);

    return {
      firstAnswerVisible,
      secondAnswerVisible,
      thirdAnswerVisible,
      fourthAnswerVisible,
      fifthAnswerVisible,
      sixthAnswerVisible,
      seventhAnswerVisible,
    };
  },
});
</script>
