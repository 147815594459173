<template>
  <div class="flex flex-col gap-4 bg-gray-50 max-w-screen-xl mx-auto">
    <!-- loader -->
    <div
      v-if="isLoading"
      class="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50"
    >
      <!-- Spinner -->
      <div
        class="animate-spin rounded-full h-32 w-32 border-t-4 border-b-4 border-primary-purple"
      ></div>
    </div>
    <!-- header -->
    <div class="flex flex-col mt-8 px-16">
      <div class="flex flex-row justify-between items-center gap-2">
        <div class="flex-shrink-0">
          <img
            :src="companyLogo()"
            alt="Logo"
            class="object-contain max-w-40 max-h-24 rounded-md"
          />
        </div>
        <div class="flex flex-col">
          <BaseButton
            type="button"
            @click="joinExpertProgram()"
            class="px-4 py-2 text-xs sm:text-sm font-medium leading-none text-white border border-gray-300 rounded-full"
            :style="{ backgroundColor: primaryColor }"
          >
            Join Now
          </BaseButton>
        </div>
      </div>
    </div>
    <!-- section 1 -->
    <div class="flex flex-col px-4 sm:px-16 mt-6">
      <SectionOne
        :primaryColor="primaryColor"
        :description="description"
        :joinExpertProgram="joinExpertProgram"
      />
    </div>
    <div class="flex flex-col px-4 sm:px-16">
      <SectionTwo :primaryColor="primaryColor" />
    </div>
    <div class="flex flex-col mb-20 sm:my-20">
      <SectionThree :primaryColor="primaryColor" />
    </div>
    <div class="flex flex-col px-4 sm:px-16 mt-6 sm:mt-40">
      <SectionFour
        :primaryColor="primaryColor"
        :joinExpertProgram="joinExpertProgram"
      />
    </div>
    <div class="flex flex-col px-4 sm:px-16 sm:mt-40 mb-2 sm:mb-[5rem]">
      <SectionFive />
    </div>
    <div class="flex flex-col px-4 sm:px-16 my-2 sm:my-0">
      <SectionSix />
    </div>
    <div class="flex flex-col mt-2 sm:mt-[5rem]">
      <SectionSeven
        :primaryColor="primaryColor"
        :joinExpertProgram="joinExpertProgram"
      />
    </div>
    <div class="flex flex-col my-10 items-center">
      <div col="flex flex-col">
        <span>Powered by</span>
      </div>
      <a
        href="http://slashexperts.com/"
        target="_blank"
        rel="noopener noreferrer"
      >
        <div class="flex flex-col">
          <img src="@/assets/shared/company-logo.svg" alt="logo" />
        </div>
      </a>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import BaseButton from "@/components/shared/BaseButton.vue";
import SectionOne from "@/components/expert-program/overview/SectionOne.vue";
import SectionTwo from "@/components/expert-program/overview/SectionTwo.vue";
import SectionThree from "@/components/expert-program/overview/SectionThree.vue";
import SectionFour from "@/components/expert-program/overview/SectionFour.vue";
import SectionFive from "@/components/expert-program/overview/SectionFive.vue";
import SectionSix from "@/components/expert-program/overview/SectionSix.vue";
import SectionSeven from "@/components/expert-program/overview/SectionSeven.vue";
import { useRoute } from "vue-router";
import router from "@/router";
import { gql } from "@apollo/client/core";
import { useQuery } from "@vue/apollo-composable";

const FETCH_PUBLIC_PROGRAM = gql`
  query FetchPublicProgram($uuid: String!) {
    fetchPublicProgram(uuid: $uuid) {
      primaryColor
      logo
      website
      description
    }
  }
`;

export default defineComponent({
  name: "ExpertProgramOverviewView",
  components: {
    BaseButton,
    SectionOne,
    SectionTwo,
    SectionThree,
    SectionFour,
    SectionFive,
    SectionSix,
    SectionSeven,
  },
  setup() {
    const route = useRoute();
    const primaryColor = ref("#6A48F3"); // slashexperts primary color
    const logo = ref("");
    const website = ref("");
    const description = ref("");
    const isLoading = ref(false);

    const fetchPublicProgram = () => {
      isLoading.value = true;
      const programUuid = route.query.program as string;
      const { onResult, onError } = useQuery(FETCH_PUBLIC_PROGRAM, {
        uuid: programUuid,
      });

      onResult((result) => {
        if (result && result.data?.fetchPublicProgram) {
          primaryColor.value =
            result.data.fetchPublicProgram.primaryColor || "#6A48F3";
          logo.value = result.data.fetchPublicProgram.logo;
          website.value = result.data.fetchPublicProgram.website;
          description.value = result.data.fetchPublicProgram.description;
          isLoading.value = false;
        }
      });

      onError((error) => {
        console.log("error", error);
        isLoading.value = false;
      });
    };

    const companyLogo = () => {
      return logo.value ? logo.value : logoFromWebsite(website.value);
    };

    const logoFromWebsite = (websiteUrl: string): string => {
      const urlTemplate =
        "https://img.logo.dev/{domain}?token=pk_R1LW6BZBRPKs6t3nZphCOw";
      const placeholder = "{domain}";

      try {
        const url = new URL(websiteUrl);
        const domain = url.hostname.split(".").slice(-2).join(".");

        return urlTemplate.replace(placeholder, domain);
      } catch (error) {
        return urlTemplate.replace(placeholder, "www.logo.com");
      }
    };

    const joinExpertProgram = () => {
      if (route.query.new === "false") {
        router.push({
          name: "SignIn",
          query: { token: route.query.token },
        });
      } else if (route.query.new === "true") {
        router.push({
          name: "SignUp",
          query: { token: route.query.token },
        });
      }
    };

    onMounted(() => {
      if (route.query.program) {
        fetchPublicProgram();
      }
    });

    return {
      joinExpertProgram,
      companyLogo,
      primaryColor,
      isLoading,
      description,
    };
  },
});
</script>
